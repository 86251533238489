import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Image, Dropdown, Space, message,} from "antd";
import { DownOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";

import countryCodes from '../../data/country-codes/CountryCodes.json'


export default function SiteHeader({ backgroundColor, brandColor, brandSayingColor, menuColor, logo,
                                       brand, brandSaying, ...props}) {

    const [languageCode, setLanguageCode] = useState('gb')
    const [language, setLanguage] = useState('')

    useEffect(() => {
        const language = items.find(e => e.key.toLowerCase() === languageCode.toLowerCase())
        setLanguage(language.label)
    }, []);

    const items = [
        {
            label: (
                <div>
                    <span style={{display: 'inline-block', width: 40}}
                          role="img">
                        <Image
                            alt={"english"}
                            height={16}
                            src={`${process.env.PUBLIC_URL}/images/country-flags/gb.png`}
                            preview={false}
                        />
                    </span>
                    English
                </div>

            ),
            key: 'gb',
        },
        {
            label: (
                <div>
                    <span style={{display: 'inline-block', width: 40}}
                          role="img">
                        <Image
                            alt={"german"}
                            height={16}
                            src={`${process.env.PUBLIC_URL}/images/country-flags/de.png`}
                            preview={false}
                        />
                    </span>
                    German
                </div>

            ),
            key: 'de',
        },
        {
            label: (
                <div>
                     <span style={{display: 'inline-block', width: 40}}
                           role="img">
                        <Image
                            alt={"russian"}
                            height={16}
                            src={`${process.env.PUBLIC_URL}/images/country-flags/ru.png`}
                            preview={false}
                        />
                    </span>
                    Russian
                </div>

            ),
            key: 'ru',
        },
    ];

    function onChangeLanguage({ key }) {
        setLanguageCode(key)

        const language = items.find(e => e.key.toLowerCase() === key.toLowerCase())
        setLanguage(language.label)
    }

    return (
        <div style={{display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: backgroundColor,
            color: brandColor,
            height: 70,
            width: '100%',
        }}>
            {/* Brand */}
            <div style={{display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: 20
            }}>
                <Link to={'/'}>
                    <div style={{display: 'inline-flex'}}>
                        {/* Logo - Image */}
                        {logo.length > 0 &&
                            <div style={{backgroundColor: '#FFFFFF00', marginRight: 10}}>
                                <Image
                                    alt={"logo"}
                                    width={50}
                                    height={50}
                                    src={logo}
                                    preview={false}
                                />

                            </div>
                        }

                        {/* Brand - Text */}
                        {brand.length > 0 &&
                            <div style={{fontSize: 50, fontWeight: 'bold', color: brandColor}}>
                                {brand}
                            </div>
                        }
                    </div>
                </Link>

                {/* Brand Saying - Text */}
                {brandSaying.length > 0 &&
                    <div style={{fontSize: 20,  color: brandSayingColor.length > 0 ? brandSayingColor: brandColor,
                        transform: 'rotate(-9deg)',
                        position: 'relative', left: -70, top: 30}}
                    >
                        {brandSaying}
                    </div>
                }
            </div>


            <div  style={{display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: 20,
                color: menuColor.length > 0 ? menuColor : brandColor
            }}>

                {/* Menus */}
                <div style={{marginRight: 50}}>
                    <Link to={'/about'} style={{textDecoration: 'none'}}>
                        About
                    </Link>
                </div>

                {/* Languages */}
                <div>
                    <Dropdown disabled={true}
                        menu={{
                            items,
                            onClick: onChangeLanguage,
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                {language}
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

SiteHeader.propTypes = {
    backgroundColor: PropTypes.string,
    brandColor: PropTypes.string,
    brandSayingColor: PropTypes.string,
    menuColor: PropTypes.string,
    logo: PropTypes.string,
    brand: PropTypes.string,
    brandSaying: PropTypes.string,

};

SiteHeader.defaultProps = {
    backgroundColor: '#002766',
    brandSayingColor: '',
    brandColor: '#ffffff',
    menuColor: '',
    logo: '',
    brand: '',
    brandSaying: '',
};