import React, {useEffect, useState} from "react";

import {Layout, Modal} from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import {BsFiletypePng, BsLink45Deg, BsWifi, BsFillPersonVcardFill, BsBodyText} from "react-icons/bs";
import {IoMailOutline} from "react-icons/io5";
import QrCodeTypes from "../components/qr-code-types/QrCodeTypes";
import QrCodeSettings from "../components/qr-code-settings/QrCodeSettings";
import InputText from "../components/input/InputText";
import InputUrl from "../components/input/InputUrl";
import InputWifi from "../components/input/InputWifi";
import InputVCard from "../components/input/InputVCard";
import InputEmail from "../components/input/InputEmail";
import QRCodeDownloader from "../components/qr-code-downloader/QRCodeDownloader";
import DataProtection from "../components/dsgvo/data-protection/DataProtection";
import Imprint from "../components/dsgvo/imprint/Imprint";
import SiteFooter from "../components/site-footer/SiteFooter";
import InputTelephone from "../components/input/InputTelephone";

const { Header, Content, Footer, Sider } = Layout;

const BASE_64_PNG_PREFIX = "data:image/png;base64,"

const qrCodeStaticTypes = [{
    type: "text",
    text: "Text",
    icon: < BsBodyText />
},{
    type: "link",
    text: "Link",
    icon: < BsLink45Deg />
},{
    type: "wifi",
    text: "WIFI",
    icon: < BsWifi />
},{
    type: "vcard",
    text: "vCard",
    icon: < BsFillPersonVcardFill />
},{
    type: "email",
    text: "e-Mail",
    icon: < IoMailOutline />
},{
    type: "tel",
    text: "Telephone",
    icon: < IoMailOutline />
},]

export default function QrCodeCreationView(props) {

    const [userInput, setUserInput] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [qrType, setQrType] = useState(qrCodeStaticTypes[1].type)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    async function downloadQrCode(settings) {
        let url = process.env.REACT_APP_QR_CODE_SERVER_DOMAIN

        if (process.env.REACT_APP_QR_CODE_SERVER_PORT.length > 0) {
            url += (':' + process.env.REACT_APP_QR_CODE_SERVER_PORT)
        }

        url += process.env.REACT_APP_QR_CODE_SERVER_PATH

        settings.data = userInput

        fetch(url, {
            method: 'POST',
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settings)
        })
            .then(result => result.json())
            .then((output) => {
                if (typeof output !== 'undefined' && typeof output.qr_base64 === "string") {
                    setImageSrc(BASE_64_PNG_PREFIX + output.qr_base64)
                }

                setIsLoading(false)
                setIsError(false)
            }).catch(err => {
            console.error(err)
                setIsLoading(false)
                setIsError(true)
        });
    }

    async function onClickGenerate(settings) {
        setImageSrc('')

        setShowModal(true)
        setIsLoading(true)
        setIsError(false)

        await downloadQrCode(settings)
    }

    function getUserInput(type) {

        switch (type) {
            case qrCodeStaticTypes[0].type:
                return <InputText onChange={(v)=>setUserInput(v)}/>
                break

            case qrCodeStaticTypes[1].type:
                return <InputUrl onChange={(v)=>setUserInput(v)}/>
                break

            case qrCodeStaticTypes[2].type:
                return <InputWifi onChange={(v)=>setUserInput(v)}/>
                break

            case qrCodeStaticTypes[3].type:
                return <InputVCard onChange={(v)=>setUserInput(v)}/>
                break

            case qrCodeStaticTypes[4].type:
                return <InputEmail onChange={(v)=>setUserInput(v)}/>
                break

            case qrCodeStaticTypes[5].type:
                return <InputTelephone onChange={(v)=>setUserInput(v)}/>
                break

            default:
                return <InputText onChange={(v)=>setUserInput(v)}/>
        }
    }

    const handleOk = () => {
        setShowModal(false);
    };
    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            // backgroundColor: '#fafafa',
            height: '100vh', width: '100%'}}>

            {/*<div style={{position: 'relative', top: 0,*/}
            {/*    marginLeft: -200,*/}
            {/*    marginBottom: -10}}>*/}
            {/*    <div style={{color: '#000000',*/}
            {/*        fontSize: 40, fontWeight: 'bold'}}>*/}
            {/*        Easy QR Code Generator*/}
            {/*    </div>*/}

            {/*    <div style={{display: 'flex', justifyContent: 'flex-end'}}>*/}
            {/*        <div style={{fontSize: 23, marginBottom: 10, marginRight:-30,*/}
            {/*            color: '#8c8c8c'}}>Create and Generate</div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* Modal Download Window */}
            <Modal title={null}
                   open={showModal}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={isError ? true : false}
            >
                <QRCodeDownloader isLoading={isLoading}
                                  isError={isError}
                                  src={imageSrc}
                                  qrType={qrType}
                />
            </Modal>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',
                width: '100%',
                maxWidth: 1200,
                height: '100%'}}>

                <div style={{display: 'flex', flexDirection: 'column',
                    justifyContent: 'flex-start', alignItems: 'center',
                    marginTop: 40, marginRight: 20}}>

                    <div style={{height: 60, width: 500,}}>
                        <QrCodeTypes direction={'row'} opChange={(v)=>setQrType(v)} />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column',
                        justifyContent: 'flex-start', alignItems: 'center',
                        marginTop: 50, width: '80%'}}>
                        {getUserInput(qrType)}
                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                    marginTop: 10,
                    height: '100%'}}>
                    <QrCodeSettings onClickGenerate={(settings)=>onClickGenerate(settings)}/>
                </div>
            </div>

        </div>

    )
}