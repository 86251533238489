import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Image} from 'antd';

const QR_CODE_SIZE = 200
const QR_BORDER = 4
const QR_CELL_SIZE = QR_CODE_SIZE
const QR_CODE_IMAGE_SIZE = 50

const QR_FINDER_STYLES = {
    CIRCLE: "circle",
    SQUARE: "square",
    ROUND_SQUARE: "round_square",
    ROUND_SQUARE_02: "round_square_02",
}

const QR_PATTERN = {
    CIRCLE: "circle",
    SQUARE: "square",
}

const QR_CODE_VERSION = {
    "1": 21,
    "2": 25,
    "3": 29,
    "4": 33,
    "5": 37,
    "6": 41,
    "7": 45,
    "8": 49,
    "9": 53
}

export default function QRCodePreview({eyesPattern, codePattern,
                                          logo,
                                          backgroundColor, codeColor, outerEyeColor, innerEyeColor,
                                            qrCodeVersion,
                                          ...props}) {

    const [qrCellSize, setQrCellSize] = useState(QR_CODE_SIZE / QR_CODE_VERSION[qrCodeVersion.toString()])
    const [qrRandomCode, setQrRandomCode] = useState([])
    const [offset, setOffset] = useState(0)
    const [logoSize, setLogoSize] = useState(5)

    useEffect(() => {
        setQrRandomCode(getRandomFields(qrCodeVersion))
        const qrCellSize = QR_CODE_SIZE / QR_CODE_VERSION[qrCodeVersion.toString()]

        const digitStr = (qrCellSize).toString().split(".")[1]
        const logoSize = (((QR_CODE_SIZE / 100) * 28) / qrCellSize).toString().split(".")[0]

        setQrCellSize(qrCellSize)
        setOffset(parseFloat("0." + digitStr))
        setLogoSize(logoSize)
    }, [qrCodeVersion]);

    function getRandomFields(qrVersion) {
        const fields = []

        for (let i=0; i<QR_CODE_VERSION[qrVersion.toString()]; i++) {
            const row = []
            for (let ii=0; ii<QR_CODE_VERSION[qrVersion.toString()]; ii++) {
                row.push(
                    Math.random()
                )
            }

            fields.push(row)
        }

        return fields
    }

    function getEysPattern(size, eyeIndex) {
        const cellSize = QR_CODE_SIZE / size
        let borderRadius = 0
        let eyeRotation = 0

        switch (eyesPattern) {
            case QR_FINDER_STYLES.SQUARE:
                borderRadius = 0
                break

            case QR_FINDER_STYLES.ROUND_SQUARE:
                borderRadius = qrCellSize * 1
                break

            case QR_FINDER_STYLES.ROUND_SQUARE_02:
                borderRadius = `0px ${qrCellSize * 1}px 0px ${qrCellSize * 1}px`
                break

            case QR_FINDER_STYLES.CIRCLE:
                borderRadius = qrCellSize * 7
                break

            default:
                borderRadius = 0
        }

        switch (eyeIndex) {
            case 1:
                eyeRotation = 0
                break

            case 2:
                eyeRotation = 90
                break

            case 3:
                eyeRotation = 90
                break

            default:
                eyeRotation = 0
        }

        return (
            <div style={{width: cellSize * 7, height: cellSize * 7,
                backgroundColor: outerEyeColor,
                borderRadius: borderRadius,
                transform: `rotate(${eyeRotation}deg)`}}
            >

                <div style={{position: 'absolute',
                    width: cellSize * 5, height: cellSize * 5,
                    marginTop: cellSize, marginLeft: cellSize,
                    backgroundColor: backgroundColor,
                    borderRadius: borderRadius}}
                >

                    <div style={{position: 'absolute',
                        width: cellSize * 3, height: cellSize * 3,
                        marginTop: cellSize, marginLeft: cellSize,
                        backgroundColor: innerEyeColor,
                        borderRadius: borderRadius}}
                    >
                    </div>
                </div>
            </div>
        )
    }

    function getQrCodeField() {
        const fields = []

        for (let i=0; i<qrRandomCode.length; i++) {
            const row = []

            for (let ii=0; ii<qrRandomCode[i].length; ii++) {
                row.push(
                    <div key={'el_' + i + '_' + ii}
                         style={{width: qrCellSize,
                        height: qrCellSize,
                        backgroundColor: qrRandomCode[i][ii] >= 0.5 ? codeColor : backgroundColor,
                        borderRadius: codePattern === QR_PATTERN.SQUARE ? 0 : (QR_CODE_SIZE / qrRandomCode[i].length)}}></div>
                )
            }

            fields.push(row)
        }

        return fields
    }

    return (
        <div >

            {/* QR Code */}
            <div style={{
                width: QR_CODE_SIZE + (qrCellSize * QR_BORDER) * 2,
                height: QR_CODE_SIZE + (qrCellSize * QR_BORDER) * 2,
                backgroundColor: backgroundColor,
                borderRadius: 7}}>

                {/* QR Code */}
                <div style={{paddingLeft: qrCellSize * QR_BORDER,
                                paddingTop: qrCellSize * QR_BORDER}}>

                    {/* Code */}
                    <div style={{backgroundColor: 'rgba(255,255,255,0)',
                        position: 'relative', top: 0, left: 0}}>
                        {getQrCodeField().map((e,i)=>{
                            return <div key={'row_' + i}
                                        style={{display: 'flex', flexDirection: 'row'}}>{e}</div>
                        })}
                    </div>

                    {/* Eys */}
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                        position: 'relative',
                        width: QR_CODE_SIZE, height: QR_CODE_SIZE,
                        marginTop: -QR_CODE_SIZE}}>

                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div style={{width: qrCellSize * 7,
                                height: qrCellSize * 7,
                                borderRightWidth: qrCellSize + offset,
                                borderRightColor: backgroundColor,
                                borderRightStyle: 'solid',
                                borderBottomWidth: qrCellSize + offset,
                                borderBottomColor: backgroundColor,
                                borderBottomStyle: 'solid',
                                backgroundColor: backgroundColor}}>

                                {getEysPattern(QR_CODE_VERSION[qrCodeVersion.toString()], 1)}
                            </div>

                            <div style={{width: qrCellSize * 7,
                                height: qrCellSize * 7,
                                borderLeftWidth: qrCellSize + offset,
                                borderLeftColor: backgroundColor,
                                borderLeftStyle: 'solid',
                                borderBottomWidth: qrCellSize + offset,
                                borderBottomColor: backgroundColor,
                                borderBottomStyle: 'solid',
                                backgroundColor: backgroundColor}}>

                                {getEysPattern(QR_CODE_VERSION[qrCodeVersion.toString()], 2)}
                            </div>
                        </div>


                        <div style={{position: 'relative',
                            width: qrCellSize * 7,
                            height: qrCellSize * 7,
                            borderTopWidth: qrCellSize + offset,
                            borderTopColor: backgroundColor,
                            borderTopStyle: 'solid',
                            borderRightWidth: qrCellSize + offset,
                            borderRightColor: backgroundColor,
                            borderRightStyle: 'solid',
                            backgroundColor: backgroundColor}}>

                            {getEysPattern(QR_CODE_VERSION[qrCodeVersion.toString()], 3)}
                        </div>
                    </div>

                    {/* Image */}
                    {logo.length > 0 &&
                        <div style={{position: 'relative',
                            width: qrCellSize * logoSize, height: qrCellSize * logoSize,
                            marginTop: ((-QR_CODE_SIZE / 2) - (qrCellSize * (logoSize / 2)) ),
                            marginLeft: ((QR_CODE_SIZE / 2) + (qrCellSize * (logoSize / 2))  - (qrCellSize * logoSize)),

                            backgroundColor: 'rgba(255,255,255,0)'}}>

                            <Image
                                width={qrCellSize * logoSize}
                                height={qrCellSize * logoSize}
                                src={logo}
                                preview={false}
                            />

                        </div>
                    }
                </div>

            </div>

        </div>
    )
}

QRCodePreview.propTypes = {
    eyesPattern: PropTypes.oneOf(["circle", "square",  "round_square", "round_square_02"]),
    codePattern: PropTypes.oneOf(["circle", "square"]),
    logo: PropTypes.string,
    backgroundColor: PropTypes.string,
    codeColor: PropTypes.string,
    outerEyeColor: PropTypes.string,
    innerEyeColor: PropTypes.string,
    qrCodeVersion: PropTypes.oneOf([1,2,3,4,5,6,7,8,9]),
};

QRCodePreview.defaultProps = {
    eyesPattern: QR_FINDER_STYLES.SQUARE,
    codePattern: QR_PATTERN.SQUARE,
    logo: '',
    backgroundColor: '#ffffff',
    codeColor: '#000000',
    outerEyeColor: '#000000',
    innerEyeColor: '#000000',
    qrCodeVersion: 3
};