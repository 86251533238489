import React, {useState} from 'react';
import { Image } from 'antd';
import ReactMarkdown from "react-markdown";


const ACTIVE_URL_COLOR = '#4e62fc'
const INACTIVE_URL_COLOR = '#000000'
export default function Feature ({src, icon, bgColor, title, content, imgPosition, url}) {
    const [urlColor, setUrlColor] = useState(INACTIVE_URL_COLOR)

    function onElementActive() {
        setUrlColor(ACTIVE_URL_COLOR)
    }
    function onElementInactive() {
        setUrlColor(INACTIVE_URL_COLOR)

    }
    function onClickMore(url) {
        console.log("Go to URL: " + url)

    }

    return (
        <div style={{
            display: 'flex',
            flexFlow: 'row',
            flexDirection: (typeof imgPosition === 'undefined' || imgPosition === 'left') ? 'row' : 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: 400,
            paddingTop: 25,
            paddingBottom: 25,
            backgroundColor: typeof bgColor === 'undefined' ? '#d2ffd4' : bgColor,
            // background: "linear-gradient(180deg, #fdfbfb 0%, #ebedee 50%)"
        }}

             onMouseOver={()=>onElementActive()}
             onMouseLeave={()=>onElementInactive()}
        >
            {/* Image */}
            <div style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: (typeof imgPosition === 'undefined' || imgPosition === 'left') ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                margin: (typeof imgPosition === 'undefined' || imgPosition === 'left') ? '0px 35px 0px 0px' : '0px 0px 0px 35px',
                width: '40%',
                borderRadius: 7, padding: 15,
            }}>
                <Image
                    alt={"image"}
                    style={{maxHeight: 300}}
                    preview={false}
                    src={src}
                />
            </div>

            {/* Content */}
            <div style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '60%',
                minHeight: 300,
                maxWidth: 650,
                borderRadius: 7, padding: 15,
                backgroundColor: '#f4f4f4'
            }}>


                <div style={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 12,
                }}>
                    {/* Icon */}
                    <div style={{height: 70, marginTop: 12, marginBottom: 12,}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexFlow: 'row',
                            backgroundColor: '#c9cffe',
                            width: 30, height: 30,
                            borderRadius: '50%', padding: 15,
                            fontSize: 24
                        }}
                        >
                            <Image
                                alt={"icon"}
                                style={{color: '#ffffff'}}
                                preview={false}
                                width={200}
                                src={icon}
                            />
                        </div>
                    </div>

                    {/* Header */}
                    <div style={{
                        marginLeft: 30,
                        fontSize: 24, fontWeight: 'bold',
                        color: '#000000',
                    }}>{title}</div>
                </div>


                {/* Divider */}
                <div style={{width: '10%', backgroundColor: '#ff8c00', height: 2,
                    marginTop: 12, marginBottom: 12}}></div>

                {/* Content */}
                <div style={{
                    minHeight: 155,
                    marginTop: 12,
                    fontSize: 20,
                    color: '#000000'
                }}>
                    <ReactMarkdown>{content}</ReactMarkdown>
                </div>

                {/* URL */}
                {url &&
                    <div style={{
                        display: 'flex',
                        flexFlow: 'row',
                        width: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <div style={{marginTop: 12, marginBottom: 12, fontSize: 20,
                            color: urlColor}}

                             onClick={() => onClickMore(url)}>more
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}