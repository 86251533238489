import React, {useEffect, useState} from "react";
import {Select, Checkbox, message, Image, Space, Input} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';


export default function InputWifi(props) {
    const [ssid, setSsid] = React.useState("");
    const [transmission, setTransmission] = React.useState("WPA2-EAP");
    const [password, setPassword] = React.useState("");
    const [hidden, setHidden] = useState(false)

    useEffect(()=>{

        if (typeof props !== 'undefined' && typeof props.onChange !== 'undefined') {
            const wifiCode = `WIFI:T:${transmission};S:${ssid};P:${password};H:${hidden};`

            props.onChange(wifiCode)
        }

    }, [ssid, transmission, password, hidden])

    function onSsidChanged(v) {
        setSsid(v)
    }

    function onTransmissionChanged(v) {
        setTransmission(v)
    }

    function onPasswordChanged(v) {
        setPassword(v)
    }

    function onHiddenChanged(v) {
        setHidden(v)
    }

    return (
        <div >

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 500}}>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>onSsidChanged(e.target.value)}
                               placeholder="SSID" />
                    </div>
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Select
                            defaultValue="WPA2-EAP"
                            value={transmission}
                            style={{ width: 200 }}
                            onChange={(v)=>onTransmissionChanged(v)}
                            options={[
                                {
                                    label: "No Password",
                                    value: "nopass"
                                },
                                {
                                    label: "WEP",
                                    value: "WEP"
                                },
                                {
                                    label: "WPA",
                                    value: "WPA"
                                },
                                {
                                    label: "WPA2-EAP",
                                    value: "WPA2-EAP"
                                },

                            ]}
                        />
                    </div>
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <Input.Password
                        onChange={(e)=>onPasswordChanged(e.target.value)}
                        placeholder="Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <Checkbox onChange={(e)=>onHiddenChanged(e.target.checked)}>Hidden</Checkbox>
                </div>
            </div>
        </div>
    )
}