import React, {useEffect, useState} from "react";
import {Select, Checkbox, message, Image, Space, Input} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {BsFiletypePng, BsLink45Deg, BsWifi, BsFillPersonVcardFill, BsBodyText} from "react-icons/bs";
import {IoMailOutline} from "react-icons/io5";
import QrCodeSettings from "../qr-code-settings/QrCodeSettings";



export default function InputEmail(props) {
    const [email, setEmail] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");

    const { TextArea } = Input;

    useEffect(()=>{
        if (typeof props !== 'undefined' && typeof props.onChange !== 'undefined') {
            props.onChange(`mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`)
        }

    }, [email, subject, message])

    return (
        <div >

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 500}}>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setEmail(e.target.value)}
                               placeholder={'Email'}/>
                    </div>
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setSubject(e.target.value)}
                               placeholder={'Subject'}/>
                    </div>
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <TextArea onChange={(e)=>setMessage(e.target.value)}
                                  placeholder={'Message'}
                                  rows={10}
                                  maxLength={4000}
                        />
                    </div>
                </div>


            </div>

        </div>
    )
}