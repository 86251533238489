import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";
import {Image} from "antd";
import Feature from "../feature/Feature";
import {BsFillRocketTakeoffFill} from "react-icons/bs";

import texts from '../../data/about-texts/AboutTexts.json'


export default function About({ ...props}) {

    return (
        <div style={{display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 1200,
            height: '100%',
        }}>
            {texts.map((e,i)=>{
                return (
                    <Feature key={'feature_' }
                             imgPosition={((i % 2) === 0) ? 'left' : 'right'}
                             src={`${process.env.PUBLIC_URL}/${e?.image}`}
                             icon={`${process.env.PUBLIC_URL}/${e?.icon}`}
                             title={e?.title}
                             content={e?.content}
                             bgColor={'rgba(204,255,180,0)'}
                    />
                )
            })}

        </div>
    )
}

About.propTypes = {
    backgroundColor: PropTypes.string,
    brandColor: PropTypes.string,
    brandSayingColor: PropTypes.string,
    menuColor: PropTypes.string,
    logo: PropTypes.string,
    brand: PropTypes.string,
    brandSaying: PropTypes.string,

};

About.defaultProps = {
    backgroundColor: 'rgba(255,255,255,0)',
    brandSayingColor: '',
    brandColor: '#000000',
    menuColor: '',
    logo: '',
    brand: '',
    brandSaying: '',
};