import React, {useEffect, useState} from "react";


export default function Imprint(props) {

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent}} />
    )
}

const htmlContent = `<h1>Impressum</h1>
<h2 id="m46">Diensteanbieter</h2>
<p>Eugen Klexin</p>
<p>Montessoriweg 16</p>
<p>48159 Münster</p>
<p>Germany</p>

<h2 id="m56">Kontaktmöglichkeiten</h2>E-Mail-Adresse: <p><a href="mailto:w33kendapp@gmail.com">w33kendapp@gmail.com</a></p>

<p class="seal"><a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
`
