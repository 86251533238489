import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";



export default function SiteFooter(props) {

    return (
        <div style={{display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            backgroundColor: '#f0f0f0',
            color: '#ffffff',
            width: '100%',
        }}>

            <div style={{display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#ffffff',
                width: 260, height: 70}}>

                <Link to={`/data-protection`}>Datenschutzerklärung</Link>
                <Link to={`/imprint`}>Impressum</Link>

            </div>

        </div>
    )
}