import React, {useEffect, useState} from "react";

import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import {BsFiletypePng, BsLink45Deg, BsWifi, BsFillPersonVcardFill, BsBodyText} from "react-icons/bs";
import {IoMailOutline} from "react-icons/io5";
import QrCodeTypes from "../components/qr-code-types/QrCodeTypes";
import QrCodeSettings from "../components/qr-code-settings/QrCodeSettings";
import InputText from "../components/input/InputText";
import InputUrl from "../components/input/InputUrl";
import InputWifi from "../components/input/InputWifi";
import InputVCard from "../components/input/InputVCard";
import InputEmail from "../components/input/InputEmail";
import QRCodeDownloader from "../components/qr-code-downloader/QRCodeDownloader";
import DataProtection from "../components/dsgvo/data-protection/DataProtection";
import Imprint from "../components/dsgvo/imprint/Imprint";
import SiteFooter from "../components/site-footer/SiteFooter";
import QrCodeCreationView from "./QrCodeCreationView";
import SiteHeader from "../components/site-header/SiteHeader";
import {Route, Router, Routes} from "react-router-dom";
import About from "../components/about/About";

const { Header, Content, Footer, Sider } = Layout;

const BASE_64_PNG_PREFIX = "data:image/png;base64,"

const qrCodeStaticTypes = [{
    type: "text",
    text: "Text",
    icon: < BsBodyText />
},{
    type: "link",
    text: "Link",
    icon: < BsLink45Deg />
},{
    type: "wifi",
    text: "WIFI",
    icon: < BsWifi />
},{
    type: "vcard",
    text: "vCard",
    icon: < BsFillPersonVcardFill />
},{
    type: "email",
    text: "e-Mail",
    icon: < IoMailOutline />
},]

export default function MainView(props) {

    const [userInput, setUserInput] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [qrType, setQrType] = useState(qrCodeStaticTypes[1].type)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const firstColor = '#ffffff'
    const secondColor = '#ffffff'
    const rotation = 135
    const percent = 80


    return (
        <div>
            {/*    Header */}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <SiteHeader logo={"/images/logo-150.png"}
                            brand={'Easy QR'}
                            brandSaying={'Create and Generate'}
                            backgroundColor={'#f0f0f0'}
                            brandColor={'#a00f80'}
                            brandSayingColor={'#ea137a'}
                            menuColor={'#261669'}
                />
            </div>

            {/*    Content */}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center',
                background: `linear-gradient(${rotation}deg, ${firstColor} 0%, ${secondColor} ${percent}%)`,
                backgroundColor: '#051622',
            }}>

                <Routes>
                    <Route path="/*"wq
                           element={<QrCodeCreationView />} />

                    <Route path={"imprint"}
                           element={<Imprint />} />

                    <Route path={"data-protection"}
                           element={<DataProtection />} />

                    <Route path={"about"}
                           element={<About />} />
                </Routes>

            </div>

            {/*    Footer */}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <SiteFooter />
            </div>
        </div>

    )
}