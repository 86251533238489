import './App.css';
import {Route, Routes} from "react-router-dom";
import QrCodeCreationView from "./views/QrCodeCreationView";
import DataProtection from "./components/dsgvo/data-protection/DataProtection";
import Imprint from "./components/dsgvo/imprint/Imprint";
import MainView from "./views/MainView";

function App() {
  return (
      <Routes>

        <Route path="/*"
               element={<MainView />} />

          {/*<Route*/}
          {/*    path="/data-protection"*/}
          {/*    element={<DataProtection />}*/}
          {/*/>*/}

          {/*<Route*/}
          {/*    path="/about"*/}
          {/*    element={<About />}*/}
          {/*/>*/}
      </Routes>

  );
}

export default App;
