import React, {useEffect, useState} from "react";
import {Select, Checkbox, message, Image, Space, Input} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {BsFiletypePng, BsLink45Deg, BsWifi, BsFillPersonVcardFill, BsBodyText} from "react-icons/bs";
import {IoMailOutline} from "react-icons/io5";
import QrCodeSettings from "../qr-code-settings/QrCodeSettings";

const { TextArea } = Input;

export default function InputUrl(props) {
    const [url, setUrl] = React.useState("");

    useEffect(()=>{
        if (typeof props !== 'undefined' && typeof props.onChange !== 'undefined') {
            props.onChange(encodeURIComponent(url))
        }
    }, [url])

    function onChangeUrl(v) {
        setUrl(v)
    }

    return (
        <div >

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 500}}>

                <TextArea onChange={(e)=>onChangeUrl(e.target.value)} rows={10} placeholder="URL"  />

            </div>

        </div>
    )
}