import React, {useEffect, useState} from "react";
import {Select, Checkbox, message, Image, Space, Input} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {BsFiletypePng, BsLink45Deg, BsWifi, BsFillPersonVcardFill, BsBodyText} from "react-icons/bs";
import {IoMailOutline} from "react-icons/io5";
import QrCodeSettings from "../qr-code-settings/QrCodeSettings";



export default function InputVCard(props) {
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [phonePrefix, setPhonePrefix] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [city, setCity] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [state, setState] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [website, setWebsite] = React.useState("");

    useEffect(()=>{
        if (typeof props !== 'undefined' && typeof props.onChange !== 'undefined') {
            props.onChange("BEGIN:VCARD\n" +
                            "VERSION:3.0\n" +
                            `N:${lastName};${firstName};;;\n` +
                            `FN:${firstName + ' ' + lastName}\n` +
                            `EMAIL:${email}\n` +
                            `TEL;TYPE=cell:(${phonePrefix}) ${phone}\n` +
                            `ADR;TYPE=home:;;${street};${city};${state};${zip};${country}\n` +
                            `ORG:${company};;${title}\n` +
                            `URL:${website}\n` +
                            "END:VCARD")
        }
    })


    return (
        <div >

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 500}}>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginTop: 7, marginBottom: 7}}>
                    <Input onChange={(e)=>setFirstName(e.target.value)} placeholder="First name" />
                    <Input onChange={(e)=>setLastName(e.target.value)} placeholder="Last name" />
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginTop: 7, marginBottom: 7}}>
                    <Input style={{width: '35%'}} onChange={(e)=>setPhonePrefix(e.target.value)} placeholder="Phone Prefix" />
                    <Input onChange={(e)=>setPhone(e.target.value)} placeholder="Phone" />
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginTop: 7, marginBottom: 7}}>
                    <Input onChange={(e)=>setCompany(e.target.value)} placeholder="Company" />
                    <Input onChange={(e)=>setTitle(e.target.value)} placeholder="Title" />
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setStreet(e.target.value)} placeholder="Street" />
                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginTop: 7, marginBottom: 7}}>
                    <Input onChange={(e)=>setCity(e.target.value)} placeholder="City" />
                    <Input onChange={(e)=>setZip(e.target.value)} placeholder="ZIP" />
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setState(e.target.value)} placeholder="State" />
                    </div>
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setCountry(e.target.value)} placeholder="Country" />
                    </div>
                </div>

                <div style={{marginTop: 7, marginBottom: 7}}>
                    <div>
                        <Input onChange={(e)=>setWebsite(e.target.value)} placeholder="Website" />
                    </div>
                </div>

            </div>

        </div>
    )
}