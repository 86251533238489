import React, {useEffect, useState} from "react";
import {Image, Input, Select, Space} from 'antd';

import countryCodes from '../../data/country-codes/CountryCodes.json'

const { Option } = Select;

export default function InputTelephone(props) {
    const [dialCode, setDialCode] = useState("");
    // const [countryCode, setCountryCode] = useState("");
    const [telephonNum, setTelephonNum] = useState("");

    useEffect(()=>{
        if (typeof props !== 'undefined' && typeof props.onChange !== 'undefined') {
            props.onChange(`tel:${dialCode}${telephonNum}`)
        }
    }, [telephonNum])

    function onChangeCountry(code) {
        // setCountryCode(code)

        const country = countryCodes.find(e => e.code.toLowerCase() == code.toLowerCase())
        setDialCode(country?.dial_code)
    }


    return (
        <div >

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 500}}>

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    marginTop: 7, marginBottom: 7}}>

                    <Select
                        showSearch
                        style={{
                            width: '100%',
                            marginBottom: 14
                        }}
                        onChange={(e)=>onChangeCountry(e)}
                        placeholder="Country"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }

                        // options = {countries}
                    >
                        {countryCodes.map(e=>{
                            return (
                                <Option value={e?.code.toLowerCase()}
                                        label={e?.name}>
                                    <Space>
                                    <span style={{display: 'inline-block', width: 40}}
                                            role="img"
                                             aria-label={e?.dial_code.toLowerCase()}>
                                        <Image
                                            alt={"language"}
                                            height={16}
                                            src={`${process.env.PUBLIC_URL}/images/country-flags/${e?.code.toLowerCase()}.png`}
                                            preview={false}
                                        />
                                    </span>
                                        {`${e?.name }`}
                                    </Space>
                            </Option>
                            )
                        })}
                    </Select>

                    <Space.Compact>
                        <Input
                            style={{
                                width: 90,
                            }}
                            placeholder="Code"
                            value={dialCode}
                        />
                        <Input onChange={(e)=>setTelephonNum(e.target.value)} placeholder="Phone" />
                    </Space.Compact>


                </div>
            </div>

        </div>
    )
}