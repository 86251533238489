import React from 'react';
import Icon from '@ant-design/icons';

const SvgIcon = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 39.952408 39.952412">
        <g
            id="layer1"
            transform="translate(-66.139513,-63.28187)">
            <path
                id="rect1514"
                style={{fill:'none', fillOopacity:1, stroke: '#000000', strokeWidth:1, strokeLinecap:'round', strokeLinejoin:'miter',strokeDasharray:'none',strokeOpacity:1,paintOrder:'markers'}}
                d="m 73.00224,63.781868 h 26.226946 c 1.762474,0 6.363374,0.02133 6.363374,0.02133 0,0 -6.4e-4,4.57892 -6.4e-4,6.341396 v 26.22695 c 0,3.524952 -2.83778,6.362736 -6.362734,6.362736 H 73.00224 c -1.762476,0 -6.345973,0.009 -6.345973,0.009 0,0 -0.01676,-4.60909 -0.01676,-6.371566 V 83.258069 70.144594 c 0,-3.524952 2.837777,-6.362729 6.362729,-6.362729 z" />
            <path
                id="rect1514-3"
                style={{fill:'#000000',fillOpacity:1,stroke:'#000000',strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'miter',strokeDasharray:'none',strokeOpacity:1,paintOrder:'markers'}}
                d="m 80.03511,71.928368 h 11.97475 l 5.401174,0.0152 0.03196,5.417939 V 89.33626 c 0,3.009957 -2.423178,5.433135 -5.433135,5.433135 H 80.03511 c -1.504978,0 -5.421751,0.0047 -5.421751,0.0047 0,0 -0.01139,-3.932877 -0.01139,-5.437856 V 77.361503 c 0,-3.009957 2.423179,-5.433135 5.433136,-5.433135 z" />
        </g>
    </svg>
);

const RoundSquareIcon02 = (props) => <Icon component={SvgIcon} {...props} />;

export default RoundSquareIcon02