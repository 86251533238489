import React, {useEffect, useState} from "react";
import {ColorPicker, Button, Upload, message, Image, Space} from 'antd';
import {UploadOutlined, DeleteOutlined, SyncOutlined} from "@ant-design/icons";
import {BsLink45Deg, BsWifi, BsFillPersonVcardFill, BsBodyText, BsFillTelephoneFill} from "react-icons/bs";
import { TbWorldWww } from "react-icons/tb";
import {IoMailOutline} from "react-icons/io5";



const qrCodeStaticTypes = [{
    type: "link",
    text: "Website",
    icon: < TbWorldWww />
},{
    type: "wifi",
    text: "WIFI",
    icon: < BsWifi />
},{
    type: "vcard",
    text: "vCard",
    icon: < BsFillPersonVcardFill />
},{
    type: "email",
    text: "e-Mail",
    icon: < IoMailOutline />
},{
    type: "tel",
    text: "Telephone",
    icon: < BsFillTelephoneFill />
},{
    type: "text",
    text: "Text",
    icon: < BsBodyText />
}]

export default function QrCodeTypes(props) {
    const [qrType, setQrType] = useState(qrCodeStaticTypes[0].type)
    const [iconsDirection, setIconsDirection] = useState("column")

    useEffect(() => {
        if (typeof props !== 'undefined' && typeof props.direction === 'string') {
            setIconsDirection(props.direction)
        }
    }, [props]);

    useEffect(()=>{
        if (typeof props === 'undefined' || typeof props.opChange === 'undefined')
            return

        props.opChange(qrType)
    }, [qrType])


    return (
        <div style={{display: 'flex', flexDirection: iconsDirection, justifyContent: 'center',
            alignItems: 'center', color: '#262626',
            borderRadius: 7, width: '100%'}}>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                backgroundColor: '#f5f5f5', padding: 5, margin: 5,
                borderRadius: 7}}>

                <div style={{display: 'flex', flexDirection: iconsDirection, alignItems: 'center', justifyContent: 'center'}}>
                    {qrCodeStaticTypes.map((e, i)=>{
                        return (
                            <div key={'qrType_' + i}
                                 style={{display: 'flex', flexDirection: 'column', alignItems: 'center',
                                     margin: '5px 15px 5px 15px ',
                                     color: '#262626',
                            marginBottom: iconsDirection === 'column' ? 15 : 0}}>
                            <Button block
                                    style={{paddingTop: 0,
                                        marginLeft: 5, marginRight: 5, marginBottom: 5,
                                        height: 40, width: 50,
                                        fontSize: 25,
                                        color: qrType === qrCodeStaticTypes[i].type ? "#ffffff" : '#000000'
                            }}
                                    type={qrType === qrCodeStaticTypes[i].type ? "primary" : 'default'}
                                    icon={e.icon}
                                    onClick={()=>setQrType(e.type)}
                            >
                            </Button>
                                {e.text}
                            </div>
                        )
                    })}
                </div>

            </div>

        </div>
    )
}