import React from 'react';
import Icon from '@ant-design/icons';

const CircleSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 39.952408 39.952412">
        <g
            id="layer1"
            transform="translate(-66.139513,-63.28187)">
            <rect
                id="rect1514"
                style={{fill:'none', fillOopacity:1, stroke: '#000000', strokeWidth:1, strokeLinecap:'round', strokeLinejoin:'miter',strokeDasharray:'none',strokeOpacity:1,paintOrder:'markers'}}
                width="38.952404"
                height="38.952408"
                x="66.639511"
                y="63.781868"
                ry="19.476202" />
            <rect
                id="rect1514-3"
                style={{fill:'#000000',fillOpacity:1,stroke:'#000000',strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'miter',strokeDasharray:'none',strokeOpacity:1,paintOrder:'markers'}}
                width="22.841021"
                height="22.841024"
                x="74.601974"
                y="71.928368"
                ry="11.42051" />
        </g>
    </svg>
);

const CircleIcon = (props) => <Icon component={CircleSvg} {...props} />;

export default CircleIcon