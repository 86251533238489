import React, {useEffect, useState} from "react";


export default function DataProtection(props) {

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent}} />
    )
}

const htmlContent = `<h1>Datenschutzerklärung</h1>
<h2>Allgemeiner Hinweis und Pflichtinformationen</h2>
<h3>Benennung der verantwortlichen Stelle</h3>
<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
<p><span id="s3-t-firma">Name der Firma</span><br><span id="s3-t-ansprechpartner">Eugen Klexin</span><br><span id="s3-t-strasse">Montessoriweg 16</span><br><span id="s3-t-plz">48159</span> <span id="s3-t-ort">Münster</span></p>
<p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
<h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
<p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
<h3>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h3>
<p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
<h3>Recht auf Datenübertragbarkeit</h3>
<p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
<h3>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h3>
<p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
<h3>SSL- bzw. TLS-Verschlüsselung</h3>
<p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

<h2>Server-Log-Dateien</h2>
<p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
<ul>
\t<li>
\t\t<p>Besuchte Seite auf unserer Domain</p>
\t</li>
\t<li>
\t\t<p>Datum und Uhrzeit der Serveranfrage</p>
\t</li>
\t<li>
\t\t<p>Browsertyp und Browserversion</p>
\t</li>
\t<li>
\t\t<p>Verwendetes Betriebssystem</p>
\t</li>
\t<li>
\t\t<p>Referrer URL</p>
\t</li>
\t<li>
\t\t<p>Hostname des zugreifenden Rechners</p>
\t</li>
\t<li>
\t\t<p>IP-Adresse</p>
\t</li>
</ul>
<p>Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>


<h2>Google CDN</h2>
<h3>Art und Umfang der Verarbeitung</h3>
<p>Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer Website Google CDN. Google CDN ist ein Dienst der Google Ireland Limited, welcher auf unserer Website als Content Delivery Network (CDN) fungiert.</p>
<p>Ein CDN trägt dazu bei, Inhalte unseres Onlineangebotes, insbesondere Dateien wie Grafiken oder Skripte, mit Hilfe regional oder international verteilter Server schneller bereitzustellen. Wenn Sie auf diese Inhalte zugreifen, stellen Sie eine Verbindung zu Servern der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland her, wobei Ihre IP-Adresse und ggf. Browserdaten wie Ihr User-Agent übermittelt werden. Diese Daten werden ausschließlich zu den oben genannten Zwecken und zur Aufrechterhaltung der Sicherheit und Funktionalität von Google CDN verarbeitet.</p>
<h3>Zweck und Rechtsgrundlage</h3>
<p>Die Nutzung des Content Delivery Networks erfolgt auf Grundlage unserer berechtigten Interessen, d.h. Interesse an einer sicheren und effizienten Bereitstellung sowie der Optimierung unseres Onlineangebotes gemäß Art. 6 Abs. 1 lit. f. DSGVO.</p>
<p>Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.</p>
<h3>Speicherdauer</h3>
<p>Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Google CDN: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>


<h2>Google Analytics</h2>
<h3>Art und Umfang der Verarbeitung</h3>
<p>Wir verwenden Google Analytics von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, als Analysedienst zur statistischen Auswertung unseres Onlineangebotes. Diese umfasst beispielsweise die Anzahl der Aufrufe unseres Onlineangebotes, besuchte Unterseiten und die Verweildauer von Besuchern. Google Analytics nutzt Cookies und weitere Browser-Technologien, um Nutzerverhalten auszuwerten und Nutzer wiederzuerkennen. Diese Informationen werden unter anderem genutzt, um Berichte über die Aktivität der Website zusammenzustellen.</p>
<h3>Zweck und Rechtsgrundlage</h3>
<p>Der Einsatz von Google Analytics erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.</p>
<p>Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.</p>
<p>Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.</p>
<h3>Speicherdauer</h3>
<p>Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Google Analytics: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>


<h2>Google Ads</h2>
<h3>Art und Umfang der Verarbeitung</h3>
<p>Wir haben auf unserer Website Google Ads integriert. Google Ads ist ein Dienst von Google Ireland Limited, um Nutzern zielgerichtet Werbung anzuzeigen. Google Ads nutzt Cookies und weitere Browser-Technologien um Nutzerverhalten auszuwerten und Nutzer wiederzuerkennen.</p>
<p>Google Ads sammelt Informationen über das Besucherverhalten auf verschiedenen Websites. Diese Informationen werden verwendet, um die Relevanz der Werbung zu optimieren. Des Weiteren liefert Google Ads gezielt Werbung auf der Grundlage von Verhaltensprofilen und geografischer Lage. Dem Anbieter werden Ihre IP-Adresse und weitere Identifikationsmerkmale wie Ihr User-Agent übermittelt.</p>
<p>Wenn Sie bei einem Google Ireland Limited Dienst registriert sind, kann Google Ads den Besuch Ihrem Konto zuordnen. Selbst wenn Sie nicht bei Google Ireland Limited registriert sind oder sich nicht eingeloggt haben, ist es möglich, dass der Anbieter Ihre IP-Adresse und weitere Identifikationsmerkmale herausfindet und speichert.</p>
<p>Die Weitergabe Ihrer Daten erfolgt in diesem Fall an den Betreiber von Google Ads, die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.</p>
<h3>Zweck und Rechtsgrundlage</h3>
<p>Der Einsatz von Google Ads erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG</p>
<p>Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.</p>
<p>Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.</p>
<h3>Speicherdauer</h3>
<p>Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Google Ads: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>


<p><small>Quelle: Datenschutz-Konfigurator von <a href="https://www.mein-datenschutzbeauftragter.de" target="_blank">Mein-Datenschutzbeauftragter.de</a></small></p>
`
