import React, {useEffect, useState} from "react";
import {Input} from 'antd';

const { TextArea } = Input;

export default function InputText(props) {
    const [text, setText] = useState("");

    useEffect(()=>{
        if (typeof props !== 'undefined' && typeof props.onChange !== 'undefined') {
            props.onChange(text)
        }
    }, [text])

    return (
        <div >

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 500}}>

                <TextArea onChange={(e)=>setText(e.target.value)}
                          rows={10}
                          placeholder="Text"
                          maxLength={4000}
                />
            </div>

        </div>
    )
}